var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: { "back-button-action": _vm.backBtnAction },
        scopedSlots: _vm._u([
          {
            key: "back",
            fn: function () {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$t("checkout.back_checkout_edit")) + " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "header",
            fn: function () {
              return [
                [
                  _c(
                    "span",
                    { attrs: { "data-cy": "choose-wizard_control-text" } },
                    [_vm._v(" 3 " + _vm._s(_vm.$t("checkout.of")) + " 4 — ")]
                  ),
                ],
                _c(
                  "strong",
                  { attrs: { "data-cy": "choose-wizard_control_choose-text" } },
                  [_vm._v(" " + _vm._s(_vm.$t("checkout.credit_card")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "sub-header",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("plan")) + " "),
                _c(
                  "strong",
                  { attrs: { "data-cy": "payment_method-plan-text" } },
                  [
                    _vm._v(
                      _vm._s(_vm.getDefaultPlanName) +
                        " " +
                        _vm._s(
                          _vm.$t("recurrences_num")[
                            _vm.getPlan?.recurrencePeriod
                          ]
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(" — "),
                _c("span", {
                  attrs: { "data-cy": "choose-plan_price-text" },
                  domProps: { innerHTML: _vm._s(_vm.planPrice) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "my-5 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 m-auto",
        },
        [
          _c("label", [_vm._v(_vm._s(_vm.$t("form.card_number")))]),
          _c("div", {
            staticClass: "border",
            attrs: { id: "card-number-element" },
          }),
          _c("label", { attrs: { for: "name" } }, [
            _vm._v(_vm._s(_vm.$t("form.validity"))),
          ]),
          _c("div", {
            staticClass: "border",
            attrs: { id: "card-expiry-element" },
          }),
          _c("label", { attrs: { for: "name" } }, [
            _vm._v(_vm._s(_vm.$t("form.security_code"))),
          ]),
          _c("div", {
            staticClass: "border",
            attrs: { id: "card-cvc-element" },
          }),
          _c("submit-button-default", {
            staticStyle: { width: "100%", "max-width": "100%" },
            attrs: {
              text: _vm.$t("form.pay_and_checkout"),
              fatButton: true,
              showIcon: true,
              "data-cy": "checkout-confirm-btn",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.next()
              },
            },
          }),
        ],
        1
      ),
      _vm.newPaymentInfo.error === true
        ? _c(
            "div",
            {
              staticClass:
                "w-2/3 border border-nexred-600 bg-nexred-100 text-nexred-800 p-5 text-left inline-block",
            },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "checkout.changePayment.creditCard.creditCardError"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "font-semibold mt-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("checkout.changePayment.creditCard.suport")) +
                    " "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }